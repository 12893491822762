import React from "react"
import PropTypes from "prop-types"
import ExcluPage from "../containers/excluPage"
import atralgif from "../images/astral.gif"

const CadreAstral = (props) => {
  return (
    <>
      <ExcluPage
        title="Le cadre astral"
        description={
          <>
            Ce cadre nouvelle génération exclusivement fabriqué dans nos ateliers de Besançon saura mettre en valeur vos sujets sur support rigide (dibon,
            sublimation, ...).
            <br />
            A découvrir absolument ce nouveau concept d’encadrement.
            <br />
            Un design élégant et moderne. Le cadre ASTRAL remplace le passe-partout par du vide, et accentue la mise en valeur de votre sujet. Originalité
            garantie !
          </>
        }
        link="/cadre/Cadre-astral-ASTRAL-Noir-2072/"
        img={atralgif}
        location={props.location}
      />
    </>
  )
}

CadreAstral.propTypes = {
  identifiants: PropTypes.object,
  location: PropTypes.object,
}

export default CadreAstral
